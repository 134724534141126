import styled from "styled-components";
import { mainColor } from "../color";
export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    text-align:center;
    
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const LastDiv = styled.div`
position: relative;
top:100%;
width: 100%;
height: 5rem;
`;
export const Title = styled.h1`
    position:relative;
    width:30%;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
        width:80%;
      font-size: 1.5rem;
  }
`;

export const PermissionDiv = styled.div`
    position:relative;
    width:40%;
    height:30%;
    margin:3% auto;
    color:${mainColor};
    border: 2px solid ${mainColor}; /* Add a border with the main color */
    border-radius: 12px; /* Smooth corners with 12px radius */
    @media only screen and (max-width: 1320px) {
        width:70%;
        height:30%;
        p {
                font-size: 1rem;
            }
  }
`;
export const PermissionButton = styled.button`
    position:relative;
    width:60%;
    height:20%;
    margin:5% auto;
    color: white;
    background:red;
    border: 2px solid black; /* Add a border with the main color */
    border-radius: 12px; /* Smooth corners with 12px radius */
    @media only screen and (max-width: 1320px) {
        margin:0 auto;
        width:60%;
        height:20%;
  }
`;

export const InfoContainer = styled.div`
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 12px;
    background: #1e1e1e;
    color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
`;

export const InfoTitle = styled.h1`
    font-size: 2rem;
    margin-bottom: 20px;
    color: ${mainColor};
    text-align: center;
`;

export const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #333333;

    &:last-child {
        border-bottom: none;
    }
`;

export const Label = styled.span`
    font-weight: bold;
    color: ${mainColor};
`;

export const Value = styled.span`
    color: #ffffff;
    word-wrap: break-word;
`;

