import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { ContainerDiv, InfoTitle, LastDiv, PermissionButton, PermissionDiv, Title,Label,InfoContainer,InfoRow,Value } from "./prvacyPageElements";

export const Privacy_Page = () => {
    const [data, setData] = useState({
        ip: 'Fetching...',
        windowSize: {},
        screenSize: {},
        deviceMemory: 'Unavailable',
        cpuCores: 'Unavailable',
        userAgent: '',
        webglInfo: {},
        plugins: [],
        touchSupport: false,
        battery: {},
        connection: {},
        location: {},
        mediaDevices: [],
    });

    const [permissionGranted, setPermissionGranted] = useState(false);

    const fetchData = async () => {
        const webglInfo = getWebGLInfo();
        const ip = await getIPAddress();
        const battery = await getBatteryInfo();
        const connection = getConnectionInfo();
        const location = await getLocation();
        const mediaDevices = await getMediaDevices();

        setData({
            ip,
            windowSize: { width: window.innerWidth, height: window.innerHeight },
            screenSize: { width: window.screen.width, height: window.screen.height },
            deviceMemory: navigator.deviceMemory || 'Unavailable',
            cpuCores: navigator.hardwareConcurrency || 'Unavailable',
            userAgent: navigator.userAgent,
            webglInfo,
            plugins: [...navigator.plugins].map(plugin => plugin.name),
            touchSupport: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
            battery,
            connection,
            location,
            mediaDevices,
        });
    };

    const handlePermission = () => {
        setPermissionGranted(true);
        fetchData();
    };

    return (
        
            <ContainerDiv>
                
            {!permissionGranted ? (
                <PermissionDiv>
                    <Title>Privacy Page</Title>
                    <p>
                        This page will analyze your browser and try to find how much data your browser or you reveal about your device
                        and then display to you in order to make you aware about your privacy. Click the button below to allow us to gather and show
                        your data. We will not collect your data.
                    </p>
                    <PermissionButton onClick={handlePermission}>Allow Data Collection</PermissionButton>
                </PermissionDiv>
            ) : (
                <InfoContainer>
                <InfoTitle>Your Browser Information</InfoTitle>
                <InfoRow>
                    <Label>IP Address:</Label>
                    <Value>{data.ip}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Window Size:</Label>
                    <Value>{data.windowSize.width}x{data.windowSize.height}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Screen Size:</Label>
                    <Value>{data.screenSize.width}x{data.screenSize.height}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Device Memory:</Label>
                    <Value>{data.deviceMemory} GB</Value>
                </InfoRow>
                <InfoRow>
                    <Label>CPU Cores:</Label>
                    <Value>{data.cpuCores}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>User Agent:</Label>
                    <Value>{data.userAgent}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>WebGL Renderer:</Label>
                    <Value>{data.webglInfo.renderer || "Unavailable"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>WebGL Vendor:</Label>
                    <Value>{data.webglInfo.vendor || "Unavailable"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Browser Plugins:</Label>
                    <Value>{data.plugins.join(", ") || "None"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Touch Support:</Label>
                    <Value>{data.touchSupport ? "Yes" : "No"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Battery Level:</Label>
                    <Value>{data.battery.level ? `${data.battery.level * 100}%` : "Unavailable"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Battery Charging:</Label>
                    <Value>{data.battery.charging ? "Yes" : "No"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Connection Type:</Label>
                    <Value>{data.connection.type || "Unavailable"}</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Connection Downlink:</Label>
                    <Value>{data.connection.downlink || "Unavailable"} Mbps</Value>
                </InfoRow>
                <InfoRow>
                    <Label>Location:</Label>
                    <Value>
                        {data.location.latitude && data.location.longitude
                            ? `${data.location.latitude}, ${data.location.longitude}`
                            : "Unavailable"}
                    </Value>
                </InfoRow>
                <InfoRow>
                    <Label>Media Devices:</Label>
                    <Value>{data.mediaDevices.length > 0 ? data.mediaDevices.join(", ") : "None"}</Value>
                </InfoRow>
            </InfoContainer>
            )}
            <LastDiv></LastDiv>
        </ContainerDiv>
    );
};

// Helper functions
const getWebGLInfo = () => {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) return {};
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    return {
        renderer: gl?.getParameter(debugInfo?.UNMASKED_RENDERER_WEBGL) || 'Unavailable',
        vendor: gl?.getParameter(debugInfo?.UNMASKED_VENDOR_WEBGL) || 'Unavailable',
    };
};

const getIPAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
};

const getBatteryInfo = async () => {
    if (navigator.getBattery) {
        const battery = await navigator.getBattery();
        return {
            level: battery.level,
            charging: battery.charging,
        };
    }
    return {};
};

const getConnectionInfo = () => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    return {
        type: connection?.effectiveType,
        downlink: connection?.downlink,
    };
};

const getLocation = async () => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                () => {
                    resolve({});
                }
            );
        } else {
            resolve({});
        }
    });
};

const getMediaDevices = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.map(device => device.label || device.kind);
    }
    return [];
};
