import React,{useRef ,useState,useEffect} from "react";
import {FaBars,FaTimes} from "react-icons/fa"
import { auth } from "../Pages/firebase";
import { onAuthStateChanged } from "firebase/auth";
import "./navbar.css"

export const Navbar = ()=>{
    const navRef = useRef();
    const [authUser,setAuthUser] = useState(null);

    useEffect(()=>{
        const listen = onAuthStateChanged(auth,(user)=>{
            if(user){
                setAuthUser(user)
                
            }
            else{
                setAuthUser(null)
            }
        });

        return() =>{
            listen();
        }
    },[])

    const showNavbar = () =>{
        navRef.current.classList.toggle("responsive_nav")
    }
    if(authUser === null){
        return(
            <header>
               <a className="logo-a" href="/login">AVG</a>
               <nav className = "nav"ref={navRef}>
                   <a href="/">Home</a>
                   <a href="/projects">Projects</a>
                   <a href="/privacypage">Privacy Check 🔍</a>
                   <a href="/mrG">Mr.G's Game</a>
                   
                   <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes/>
                   </button>
               
               </nav>
               <button className="nav-btn" onClick={showNavbar}>
                   <FaBars/>
               </button>
           </header>   
       )

    }
    else if(authUser.email !== "ali@gmail.com"){
        return(
            <header>
                <h3>AVG User = {authUser.email}</h3>
                <nav className = "nav"ref={navRef}>
                        <a href="/menu">Chat Menu</a>
                        <a href="/mrG">Mr.G's Game</a>
                
                    
                    <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes/>
                    </button>
                
                </nav>
                <button className="nav-btn" onClick={showNavbar}>
                    <FaBars/>
                </button>
            </header>  
        )

    }
    else{
        return(
            <header>
               <h3>AVG Admin</h3>
               <nav className = "nav"ref={navRef}>
                   <a href="/admin">Admin Panel</a>
                    <a href="/menu">Chat Menu</a>
                    <a href="/mrG">Mr.G's Game</a>
                   <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes/>
                   </button>
               
               </nav>
               <button className="nav-btn" onClick={showNavbar}>
                   <FaBars/>
               </button>
           </header>   
       )

    }
    
    
    
}